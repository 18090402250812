import React from 'react'
import './index.css';

export default function MountEffect() {
  
    
        
        
    return (
        
            <div id="brains">
        <img id="brain" src="https://i.imgur.com/GGLq15P.png" alt="brain logo"/>
        <img id="brain2" src="https://i.imgur.com/GGLq15P.png" alt="brain logo"/>
          <img id="brain3" src="https://i.imgur.com/GGLq15P.png" alt="brain logo"/>
          <img id="brain4" src="https://i.imgur.com/GGLq15P.png" alt="brain logo"/>
          <img id="brain5" src="https://i.imgur.com/GGLq15P.png" alt="brain logo"/>
          </div>
       
    )
}
