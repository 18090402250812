import React from 'react'

//Section 3:
const Gallery = () => {
    return (
        <section id="gallery">
                <h3>Gallery</h3>
                <p>coming soon</p>
        </section>
    )
}
export default Gallery
